import { AreaUnits, CurrentUnits, PowerPerAreaUnits, EnergyUnits, FlowUnits, PowerUnits, TemperatureUnits, LengthUnits, VolumeUnits } from "../interfaces";

export default class Units {
    public static convertFlow(flow: number, units: FlowUnits): number {
        // Comes in in gal/m
        if (units === "lt/m") {
            return flow * 3.7854;
        } else if (units === 'lb/h') {
            return flow * 8.345404452021 * 60;
        }        
        return flow;
    }
    public static convertFlowToGPM(flow: number, units: FlowUnits): number {
        if (units === "lt/m") {
            return flow / 3.7854;
        } else if (units === 'lb/h') {
            return flow / (8.345404452021 * 60);
        }        
        // Goes out in gal/m
        return flow;
    }
    public static convertTemp(temp: number, units: TemperatureUnits, diff = false): number {
        // Comes in in °F
        if (units === '°C') {
            const offset = diff ? 0 : 32;
            return (temp - offset) / 1.8;
        }        
        return temp;
    }
    public static convertEnergy(energy: number, units: EnergyUnits): number {
        // Comes in in Btu
        if (units === 'kWh') {
            return energy * 0.00029307108333333;
        } else if (units === 'Wh') {
            return energy * 0.29307108333333;
        }        
        return energy;
    }
    public static convertPower(power: number, units: PowerUnits): number {
        // Comes in in Btu/h
        if (units === 'kW') {
            return power * 0.00029307108333333;
        } else if (units === 'W') {
            return power * 0.29307108333333;
        }
        return power;
    }
    public static convertArea(area: number, units: AreaUnits): number {
        // Comes in in ft²
        if (units === 'm²') {
            return area * 0.09290304;
        }        
        return area;
    }
    public static convertPowerPerArea(energyperarea: number, units: PowerPerAreaUnits): number {
        // Comes in in Btu/h/ft²
        if (units === 'kW/m²') {
            return energyperarea * 0.00029307108333333 * 10.763910417;
        } else if (units === 'W/m²') {
            return energyperarea * 0.29307108333333 * 10.763910417;
        }        
        return energyperarea;
    }
    public static convertCurrent(current: number, _units: CurrentUnits): number {
        return current;
    }
    public static convertAreaToFt2(area: number, units: AreaUnits): number {
        if (units === 'm²') {
            return area / 0.09290304;
        }
        return area;
    }
    public static convertLength(len: number, units: LengthUnits): number {
        // Comes in in ft
        if (units === 'm') {
            return len * 3.28084;
        }        
        return len;
    }
    public static convertLengthToFt(len: number, units: LengthUnits): number {
        if (units === 'm') {
            return len / 3.28084;
        }        
        return len;
    }
    public static convertVolume(volume: number, units: VolumeUnits): number {
        // Comes in in gal
        if (units === 'lt') {
            return volume * 3.785412;
        }        
        return volume;
    }

}