
import { IPump, IPumpInfo, PumpSpeed, PumpModel, PumpType, IPumpUnits, IPumpConverted } from "../interfaces";
import Units from "./Units";

export default class Pump implements IPump {
    public type: PumpType = "NONE";
    public model: PumpModel = "DC50";
    public loops: number = 0;
    public speed: PumpSpeed = "LO";
    public speedrecommended: PumpSpeed = "LO";
    public modelrecommended?: PumpModel;
    public current: number = 0;
    public enabled: boolean = false;
    public maxloops: number = 0;
    public valid: boolean = false;
    public error: string[] = [];
    public speedsetting?: PumpSpeed;
    public modelsetting?: PumpModel;
    public areasetting?: number;
    public flow: number = 0;
    public totalflow: number = 0;
    public label: string = "";
    public area: number = 0;
    public looplength: number = 0;
    public looplengthsetting?: number;
    public fluid: number = 0;

    public areaperloop = 300;

    public units: IPumpUnits = {
        current: "A",
        flow: "gal/m",
        totalflow: "gal/m",
        areaperloop: 'ft²',
        area: 'ft²',
        looplength: 'ft',
        fluid: 'gal',
    }

    public converted: IPumpConverted = {
        current: 0,
        flow: 0,
        totalflow: 0,
        areaperloop: 0,
        area: 0,
        looplength: 0,
        fluid: 0,
    };

    private _frozen = false;
    private _info: {[key: string]: IPumpInfo} = {
        "DC50": {
            speed: "LO",
            maxloops: 3,
            current: {
                "LO": [1, 0.95, 0.95],
                "MED": [1.75, 1.66, 1.6],
                "HI": [2.75, 2.6, 2.5],
                "MAX": [3.7, 4.1, 4.4],
            },
            flow: {
                "LO": [1.1, 0.75, 0.66],
                "MED": [1.25, 1, 0.75],
                "HI": [1.5, 1.2, 0.9],
                "MAX": [1.6, 1.3, 1.1],
            },
        
        },
        "DC60": {
            speed: "MED",
            maxloops: 6,
            current: {
                "LO": [1.75, 1.75, 1.75, 1.4, 1.4, 1.4],
                "MED": [3, 2.8, 2.75, 2.6, 2.6, 2.6],
                "HI": [4.6, 4.5, 4.3, 4.2, 4, 4],
                "MAX": [5, 5.3, 5.6, 5.7, 5.8, 6],
            },
            flow: {
                "LO": [1.2, 0.8, 0.7, 0.6, 0.6, 0.5],
                "MED": [1.3, 1.2, 1, 1, 0.9, 0.9],
                "HI": [1.4, 1.25, 1.2, 1.1, 1, 0.9],
                "MAX": [1.6, 1.3, 1.2, 1.2, 1.1, 1],
            },
        
        }
    }
    private _hsinfo: {[key: string]: IPumpInfo} = {
        "DC50": {
            speed: "MAX",
            maxloops: 1,
            current: {
                "LO": [2.5],
                "MED": [2.75],
                "HI": [3.24],
                "MAX": [4.36],
            },
            flow: {
                "LO": [1.2],
                "MED": [1.8],
                "HI": [2.3],
                "MAX": [3],
            },
        
        },
        "DC60": {
            speed: "MED",
            maxloops: 1,
            current: {
                "LO": [1.12],
                "MED": [3.05],
                "HI": [4.7],
                "MAX": [6.7],
            },
            flow: {
                "LO": [5.8],
                "MED": [8.2],
                "HI": [9.2],
                "MAX": [10],
            },
        
        }
    }

    private _fuse = 5;
    private _minFlow = 0.8;

    constructor(pump?: Partial<IPump>) {
        if (pump) {
            this.update(pump);
        }
    }

    public update(pump: Partial<IPump>): Pump {
        if (pump) {
            this.speedrecommended = pump.speedrecommended || this.speedrecommended;
            this.modelrecommended = pump.modelrecommended || this.modelrecommended;
            this.units = pump.units || this.units;
            this.type = pump.type || this.type;
            this.label = (pump.label !== undefined) ? pump.label : this.label;
            this.areasetting = pump.areasetting || this.areasetting;
            this.modelsetting = pump.modelsetting || this.modelsetting;
            this.speedsetting = pump.speedsetting || this.speedsetting;
            this.looplengthsetting = pump.looplengthsetting || this.looplengthsetting;
            this.model = this.modelsetting || this.modelrecommended || this.model;
            if (this.type !== "NONE") {
                this.loops = pump.loops || 1;
                if (this.type === "HEATSOURCE") {
                    const model = this._hsinfo[this.model];
                    if (model) {
                        this.speedrecommended = model.speed || "MAX";
                        this.loops = 1;
                        this.speedrecommended = model.speed;
                        this.speed = this.speedsetting || this.speedrecommended;
                        this.maxloops = 1;
                        this.current = model.current[this.speed][0] || pump.current || 0;
                        this.flow = model.flow[this.speed][0] || pump.flow || 0;
                    } else {
                        this.error.push(`Model ${model} not found`);
                    }
                } else if (this.type === "LOOP") {
                    const model = this._info[this.model];
                    if (model) {
                        this.loops = (this.loops > model.maxloops) ? model.maxloops : this.loops;
                        this.speedrecommended = this._getRecommended(model);
                        this.speed = this.speedsetting || this.speedrecommended;
                        this.maxloops = model.maxloops;
                        this.current = model.current[this.speed][this.loops - 1] || 0;
                        this.flow = model.flow[this.speed][this.loops - 1] || 0;
                        if (this.flow < this._minFlow) {
                            this.error.push(`Flow is less than the minium of ${this._minFlow} GPM that is required.`);
                        }
                    } else {
                        this.error.push(`Model ${model} not found`);
                    }
                }
                if (this.current > this._fuse) {
                    this.error.push(`Current greater than fuse value of ${this._fuse} A`);
                }
            }
        }
        if (this.loops > this.maxloops) {
            this.loops = this.maxloops;
        }

        this.totalflow = this.loops * this.flow;
        this.area = this.areasetting || (this.loops * this.areaperloop);
        this.looplength = this.looplengthsetting || this.area;

        this.enabled = this.type !== "NONE";
        if (this.enabled) {
            this.valid = this.error.length === 0;
        } else {
            this.valid = true;
        }
        if (this.looplengthsetting === this.area) {
            this.looplengthsetting = undefined;
        }
        if (this.speedsetting === this.speedrecommended) {
            this.speedsetting = undefined;
        }
        if (this.modelsetting === this.modelrecommended) {
            this.modelsetting = undefined;
        }
        this.fluid = this.looplength * 0.0092;
        this.converted = {
            current: Units.convertCurrent(this.current, this.units.current),
            flow: Units.convertFlow(this.flow, this.units.flow),
            totalflow: Units.convertFlow(this.totalflow, this.units.flow),
            areaperloop: Units.convertArea(this.areaperloop, this.units.areaperloop),
            area: Units.convertArea(this.area, this.units.area),
            looplength: Units.convertLength(this.looplength, this.units.looplength),
            fluid: Units.convertVolume(this.fluid, this.units.fluid),
        }
        return this;
    }
    public freeze(): void {
        this._frozen = true;
        Object.freeze(this);
    }
    public toJSON(): IPump {
        return {
            type: this.type,
            loops: this.loops,
            speedsetting: this.speedsetting,
            modelsetting: this.modelsetting,
            units: this.units,
            label: this.label,
            areasetting: this.areasetting,
            looplengthsetting: this.looplengthsetting,
            model: this.model,
        };
    }
    public new(pump?: Partial<IPump>): Pump {
        return new Pump({...pump, units: this.units})
    }

    private _getRecommended(model: IPumpInfo): PumpSpeed {
        const speeds: PumpSpeed[] = ["LO", "MED", "HI", "MAX"];
        let ret: PumpSpeed = this.speedrecommended;
        const min = 0.9;
        const max = 1.1;
        let miss = 100;
        let now = 0;
        if (this.type === "LOOP") {
            for (const spd of speeds) {
                const flow = model.flow[spd][this.loops - 1] || 0;
                if ((flow >= min) && (flow <=max)) {
                    return spd as PumpSpeed;
                } else if (flow < min) {
                    now = min - flow;
                    if (now < miss) {
                        miss = now;
                        ret = spd as PumpSpeed;
                    }
                } else if (flow > max) {
                    now = flow - max;
                    if (now < miss) {
                        miss = now;
                        ret = spd as PumpSpeed;
                    }
                }
            }
        }
        return ret;
    }
}