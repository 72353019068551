import React from 'react';
import { IPump, ISystem } from '../interfaces';
import PumpHeaderV from './PumpHeaderV';
import Pump from './PumpV';
import System from '../data/System';

interface PumpArgs {
    system: System;
    update: (sys: Partial<ISystem>) => void;
    reset: () => void;
}
interface PumpState {
}

export default class Pumps extends React.Component<PumpArgs, PumpState> {

    public constructor(props: PumpArgs) {
        super(props);
    }
    public setPump(index: number, pump: Partial<IPump>): void {
        const pumps: IPump[] = [];
        pumps[index] = pump as IPump;
        this.props.update({pumps});
    }
    public render(): JSX.Element {
        const pumps: number[] = [];
        for (let i = 1; i <= this.props.system.maxpumps; i++) {
            pumps.push(i);
        }
    
        return (
            <>
                <div className="Pumps" data-testid="Pumps-main-div">
                    <PumpHeaderV reset={() => this.props.reset()}/>
                    { pumps.map((i) => (<Pump heatsource={i >= this.props.system.minheatsource} key={i} index={i} Pump={this.props.system.pumps[i]} update={(pump) => this.setPump(i, pump)} />)) }
                </div>
            </>
        )
    }
}