import React from 'react';
import System from '../data/System';

interface TotalCurrentArgs {
    system: System;
}

export default function TotalCurrent({system}: TotalCurrentArgs): JSX.Element {

    let currentClass = `${(system.warning.current) ? "warning" : ""}${(system.error.current) ? "error" : ""}`;
    const currentTitle = `${(system.warning.current) ? system.warning.current : ""}${(system.error.current) ? system.error.current : ""}`;
    if (currentClass.trim().length === 0) {
        currentClass = `good`;
    }
    return (
        <div className={`TotalCurrent`} title={currentTitle}>
            <span className={currentClass}>{system.converted.current.toFixed(2)} {system.units.current}</span>
        </div>
    )
}