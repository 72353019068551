import React from 'react';
import { UnitType } from '../interfaces';

interface UnitChoiceArgs {
    value: UnitType;
    update: (type: UnitType) => void;
}

export default function UnitChoice({update, value}: UnitChoiceArgs): JSX.Element {
    return (
        <select value={value} onChange={e => update(e.target.value as UnitType)}>
            <option key="Imperial" value="Imperial">Imperial</option>
            <option key="Metric" value="Metric">Metric</option>
        </select>
    );

}