import React from 'react';
import System from '../data/System';
import { ISystem } from '../interfaces';
import Label from './Label';
import TotalCurrent from './TotalCurrent';

interface SystemInfoArgs {
    system: System;
    update: (sys: Partial<ISystem>) => void;
}

export default function SystemInfo({system, update}: SystemInfoArgs): JSX.Element {
    return (
        <>
            <div className="Item Vertical Header">
                <div><label>&nbsp;</label></div>
                <div><label>Label</label></div>
                <div><label>Floor Loops</label></div>
                <div><label>Area</label></div>
                <div><label>Loop Length</label></div>
                <div><label>Total Fluid</label></div>
                <div><label>Current</label></div>
            </div>
            <div className="Item Vertical Double" data-testid="SystemInfoV-main-div">
                <div><label>System Info</label></div>
                <div><Label value={system.label} size={18} maxlength={20} update={(label) => update({label})} /></div>
                <div>{system.loops}</div>
                <div>{system.converted.area.toFixed(0)} {system.units.area}</div>
                <div>{system.converted.looplength.toFixed(0)} {system.units.looplength}</div>
                <div>{system.converted.fluid.toFixed(1)} {system.units.fluid}</div>
                <TotalCurrent system={system} />
            </div>
        </>
    )

}