import React from 'react';
// import SystemData from '../data/System';
import System from '../data/System';
import SystemInfo from './SystemInfoV';
import { ISystem } from '../interfaces';

interface SystemArgs {
    system: System;
    update: (sys: Partial<ISystem>) => void;
}
interface SystemState {
}

export default class SystemDisplay extends React.Component<SystemArgs, SystemState> {

    public constructor(props: SystemArgs) {
        super(props);
    }
    public render(): JSX.Element {
    
        return (
            <>
                <div className="System" data-testid="Systems-main-div">
                    <SystemInfo system={this.props.system} update={(sys) => this.props.update(sys)}/>
                </div>
            </>
        )
    }
}