/**
 * @packageDocumentation
 * @author     Scott L. Price <prices@hugllc.com>
 * @copyright  © 2021 Hunt Utilities Group, LLC
 *
 * ## Introduction
 */
import EventEmitter from 'events';
import { EcommerceType, IEcommerce, IItemList, IOrder, IProdInfo } from "../interfaces";

export const productDefault: IProdInfo = {
    id: 0,
    variant: 0,
    sku: "",
    price: -1,
    name: "Unknown",
    description: "Unknown",
}


/**
 * This is the class sends stuff to shopify
 */
export class Ecommerce extends EventEmitter implements IEcommerce {
    public readonly type: EcommerceType;
    /** The URL to use */
    protected readonly url: string;
    /** The key to use */
    protected readonly key: string;
    /** The secret to use */
    protected readonly secret: string;
    /**
     * Constructor
     * 
     * @param url The URL to read the products from
     */
    constructor(type?: EcommerceType, url = "", key = "", secret = "") {
        super();
        this.type = type || "none";
        this.url = url;
        this.key = key;
        this.secret = secret;
    }
    /**
     * Checks to see if the data is ready
     * 
     * @returns True if the data is ready
     */
    public async ready(): Promise<boolean> {
        return Promise.resolve(true);
    }
    /**
     * Looks up a part number
     * 
     * @param sku The SKU to lookup
     * @returns Product information if found, default product information if not
     */
    public product(sku: string): IProdInfo {
        return productDefault;
    }
    /**
     * Adds items to the shopping cart
     * 
     * @param items The items to add to the shopping cart
     * @returns true on success, false otherwise
     */
    public async add(items: IItemList[]): Promise<boolean> {
        return false;
    }
    /**
     * Loads new data from the web
     * 
     * @returns True on success, false otherwise
     */
    public async load(): Promise<boolean> {
        return false;
    }
    /**
     * Loads new data from the web
     * 
     * @returns True on success, false otherwise
     */
    public err(): string[] {
        return [];
    }
    /**
     * Loads new dat
     * 
     * @returns True on success, false otherwise
     */
    public order(order: IOrder): Promise<boolean> {
        return Promise.resolve(false);
    }
    /**
     * Get an order
     * 
     * @param id The order ID
     * @returns The order
     */
    public getOrder(id: number): Promise<IOrder | false> {
        return Promise.resolve(false);
    }

}
