import React from 'react';
import { PumpSpeed } from '../interfaces';

interface PumpSpeedArgs {
    value: PumpSpeed;
    recommended: PumpSpeed;
    update: (type: PumpSpeed) => void;
}

export default function Speed({value, update, recommended}: PumpSpeedArgs): JSX.Element {
    return (
        <div className={`Type ${(value === recommended) ? "recommended" : ""}`} data-testid="PumpType-main-div">
            <select value={value} onChange={e => update(e.target.value as PumpSpeed)}>
                <option key="LO" value="LO">{(recommended === "LO") ? "*LO" : "LO"}</option>
                <option key="MED" value="MED">{(recommended === "MED") ? "*MED" : "MED"}</option>
                <option key="HI" value="HI">{(recommended === "HI") ? "*HI" : "HI"}</option>
                <option key="MAX" value="MAX">{(recommended === "MAX") ? "*MAX" : "MAX"}</option>
            </select>
        </div>
    )
}