import React, { useState } from 'react';
import './css/App.scss';
import Pumps from './components/Pumps';
import SystemDisplay from './components/System';
import System from './data/System';
import UnitChoice from './components/UnitChoice';
// import EType from './components/EcommerceType';
// import { Shopify } from './data/shopify';
import { EcommerceType, ISystemUnits, UnitType } from './interfaces';
import { Ecommerce } from './data/Ecommerce';
import Cookies from 'universal-cookie';


function App() {
    const cookies = new Cookies(null, { path: '/' });
    var [system, setSystem] = useState(new System({ units: getUnits(cookies.get("units")) }));
    var [ecommerce /*, setEcommerce */] = useState(getEcommerce(cookies.get("ecommerce")));
    var [ready, setReady] = useState(false);

    function getEcommerce(type: EcommerceType): Ecommerce {
        // cookies.set("ecommerce", type);
        // if (type === "shopify") {
        //     return new Shopify();
        // } else {
            return new Ecommerce();
        // }
    }

    function getUnits(type: UnitType): ISystemUnits {
        cookies.set("units", type);
        return { type } as ISystemUnits;
    }
    // This refreshes the screen when the ecommerce loads its remote data. Should only execute once.
    ecommerce.ready().then(() => {
        if (ready === false) {
            setReady(true)
        }
    });
    // ecommerce.ready().then(() => console.log("Test"));

    /*
    <div>
        <EType value={ecommerce.type} update={(val) => setEcommerce(getEcommerce(val))} />
    </div>
    */

    return (
        <div className="App">
            <Pumps system={system} reset={() => setSystem(system.new())} update={(sys) => setSystem(system.update(sys))} />
            <SystemDisplay system={system} update={(sys) => { setSystem(system.update(sys)); }} />
            <div>
                <UnitChoice value={system.units.type} update={(type) => setSystem(system.update({ units: getUnits(type) }))} />
            </div>
        </div>
    );
}

export default App;
