import React from 'react';
import { PumpModel, PumpType } from '../interfaces';

interface PumpModelArgs {
    value: PumpModel;
    update: (modelsetting: PumpModel) => void;
    recommended?: PumpModel;
    type: PumpType;
}

export default function Model({value, update, recommended, type}: PumpModelArgs): JSX.Element {
    const big = (type === "HEATSOURCE") ? (<option key="DC60" value="DC60">{(recommended === "DC60") ? "*" : ""}Big</option>) : undefined;
    return (
        <div className={`PumpModel ${(value === recommended) ? "recommended" : ""}`} data-testid="PumpModel-main-div">
            <select value={value} onChange={e => update(e.target.value as PumpModel)}>
                <option key="DC50" value="DC50">{(recommended === "DC50") ? "*" : ""}Standard</option>
                {big}
            </select>
        </div>
    )
}