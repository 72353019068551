import React from 'react';

interface PumpHeaderVArgs {
    reset: () => void;
}

export default function PumpHeaderV({reset}: PumpHeaderVArgs): JSX.Element {
    return (
        <div className="Pump Header Vertical" data-testid="PumpHeaderV-main-div">
            <div><label>Pump #</label></div>
            <div><label>Type</label></div>
            <div><label>Pump</label></div>
            <div><label>Label</label></div>
            <div><label>Loops</label></div>
            <div><label>Speed</label></div>
            <div><label>Current</label></div>
            <div><button className="Reset" value="Reset" onClick={() => reset()}>Reset</button></div>
        </div>
    )
}