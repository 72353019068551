import React from 'react';

interface PumpTypeArgs {
    value: number;
    max: number;
    update: (type: number) => void;
}

export default function Loops({value, max, update}: PumpTypeArgs): JSX.Element {
    const loops: number[] = [];
    if (max > 0) {
        for (let i = 1; i <= max; i++) {
            loops.push(i);
        }
    } else {
        loops.push(0);
    }
    return (
        <div className="Loops" data-testid="PumpType-main-div">
            <select value={value} onChange={e => update(parseInt(e.target.value))}>
                { loops.map((val) => (<option key={val} value={val}>{val}</option>)) }
            </select>
        </div>
    )
}