import React from 'react';
import { PumpType } from '../interfaces';

interface PumpTypeArgs {
    value: PumpType;
    heatsource?: boolean;
    update: (type: PumpType) => void;
}

export default function Type({value, update, heatsource}: PumpTypeArgs): JSX.Element {
    const hs = (heatsource) ? (<option key="HEATSOURCE" value="HEATSOURCE">Heater</option>) : undefined;
    return (
        <div className="PumpType" data-testid="PumpType-main-div">
            <select value={value} onChange={e => update(e.target.value as PumpType)}>
                <option key="NONE" value="NONE">None</option>
                <option key="LOOP" value="LOOP">Loop</option>
                {hs}
            </select>
        </div>
    )
}