import React from 'react';

interface LabelArgs {
    value: string;
    update: (label: string) => void;
    size?: number;
    maxlength?: number;
}

export default function Label({value, update, size, maxlength}: LabelArgs): JSX.Element {

    return (
        <div className={`Label`} data-testid="Label-main-div">
            <input 
                type="text" 
                value={value} 
                size={size || 9} 
                maxLength={maxlength || 20}
                onChange={e => update(e.target.value)}
                onKeyUp={e => { if ((e.key === 'Enter') && (document.activeElement instanceof HTMLElement)) document.activeElement.blur(); }}
            />
        </div>
    )
}