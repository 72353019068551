import React from 'react';
import PumpData from '../data/Pump';
import Model from './PumpModel';
import Type from './PumpType';
import Loops from './PumpLoops';
import Speed from './PumpSpeed';
import { IPump, PumpModel, PumpType } from '../interfaces';
import Label from './Label';

interface PumpArgs {
    index: number;
    Pump: PumpData;
    update: (pump: Partial<IPump>) => void;
    heatsource?: boolean;
}

export default function Pump({index, Pump, update, heatsource}: PumpArgs): JSX.Element {
    const type = (<Type heatsource={heatsource} value={Pump.type} update={(type: PumpType) => update(Pump.new({type, modelsetting: Pump.modelsetting}))}/>);
    if (Pump.enabled) {
        return (
            <div 
                title={!Pump.valid ? Pump.error.join("<br />") : ""}
                className={`Pump Vertical ${!Pump.valid ? "error": ""} ${Pump.enabled ? "enabled" : ""}`} 
                data-testid="PumpV-main-div"
                >
                <div>{index}</div>
                <div>{type}</div>
                <div><Model type={Pump.type} value={Pump.model} recommended={Pump.modelrecommended} update={(modelsetting: PumpModel) => update({modelsetting})}/></div>
                <div><Label value={Pump.label} update={(label) => update({label})} /></div>
                <div>{(Pump.type === "HEATSOURCE") ? "-" : (<Loops value={Pump.loops} max={Pump.maxloops} update={(loops) => update({loops})}/>)}</div>
                <div><Speed recommended={Pump.speedrecommended} value={Pump.speed} update={(speedsetting) => update({speedsetting})}/></div>
                <div>{Pump.converted.current.toFixed(2)} {Pump.units.current}</div>
                <div><button className="Reset" value="Reset" onClick={() => update(Pump.new())}>Reset</button></div>
            </div>
        )
    }
    return (
        <div className="Pump Vertical" data-testid="PumpV-main-div">
            <div>{index}</div>
            <div>{type}</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>-</div>
            <div>0 {Pump.units.current}</div>
            <div>&nbsp;</div>
        </div>
    )

}