
import { FloorType, IAccessories, IHeater, IPump, ISystem, ISystemConverted, ISystemUnits, UnitType } from "../interfaces";
import Heater from "./Heater";
import Pump from "./Pump";
import Units from "./Units";

interface ISystemErrors {
    current?: string;
}

export default class System implements ISystem {
    public readonly maxpumps = 10;
    public readonly minheatsource = 8;
    public readonly maxheaters = (this.maxpumps - this.minheatsource + 1);
    public pumps: Pump[] = [];
    public heaters: Heater[] = [];
    public loops = 0;
    public current = 0;
    public flow = 0;
    public area = 0;
    public heatercount = 0;
    
    public floor: FloorType = "CONCRETE";
    public returnwatertemp: number;
    public tanktemp: number;
    public powerneeded: number;
    public powerinput: number = 0;
    public tempdrop: number;
    public looplength: number = 0;
    public fluid: number = 8;

    public powerperarea = 25;

    public label = "";

    public error: ISystemErrors = {};
    public warning: ISystemErrors = {};

    public accessories: IAccessories = {} as IAccessories;

    public readonly units: ISystemUnits;

    public converted: ISystemConverted;

    private _accessories: IAccessories = {
        base: "STANDARD",
    }
    private _currentWarn = 20;
    private _currentErr = 25;
    constructor(sys?: Partial<ISystem>) {
        this.accessories = {...this._accessories, ...this.accessories};
        if (sys) {
            // This needs to be before the heaters or pumps.
            this.units = this._unitChoice(sys.units ? sys.units.type : "Imperial");

            this.floor = sys.floor || this.floor;
            this.powerperarea = sys.powerperarea || this.powerperarea;
            this.label = sys.label || this.label;
            this.accessories = { ...this.accessories, ...sys.accessories};
        } else {
            this.units = this._unitChoice("Imperial");
        }
        /*
        for (let i = 0; i < this.maxheaters; i++) {
            const pumpnum = (this.maxpumps - i);
            const units: IHeaterUnits = {
                maxpower: this.units.powerneeded,
                mintemp: this.units.tanktemp,
                maxtemp: this.units.tanktemp,
                maxglycol: '%',
                temp: this.units.tanktemp,
                flowrecommended: this.units.flow,
                temprecommended: this.units.tanktemp,
                output: this.units.powerneeded,
                fluid: this.units.fluid,
            };
            if (sys && sys.heaters) {
                this.heaters[i] = new Heater({...sys.heaters[i], pumpnum, units});
            } else {
                this.heaters[i] = new Heater({pumpnum, units});
            }
            this.fluid += this.heaters[i].fluid;
        }
        */
        for (let i = 1; i <= this.maxpumps; i++) {
            // const hnum = this.maxpumps - i;
            const pump: Partial<IPump> = {
                units: {
                    flow: this.units.flow,
                    current: this.units.current,
                    totalflow: this.units.flow,
                    areaperloop: this.units.area,
                    area: this.units.area,
                    looplength: this.units.looplength,
                    fluid: this.units.fluid,
                },
            }
            if (sys && sys.pumps) {
                this.pumps[i] = new Pump({...sys.pumps[i], ...pump});
            } else {
                this.pumps[i] = new Pump({type: (i === this.maxpumps) ? "HEATSOURCE" : "NONE", ...pump});
            }
            if (this.pumps[i].type === "LOOP") {
                this.loops += this.pumps[i].loops;
                this.flow += this.pumps[i].totalflow;
                this.area += this.pumps[i].area;
                this.looplength += this.pumps[i].looplength;
                this.fluid += this.pumps[i].fluid;
            } else if (this.pumps[i].type === "HEATSOURCE") {
                // this.heaters[hnum].update({pumpsetting: this.pumps[i].modelsetting, pumpspeedsetting: this.pumps[i].speedsetting});
                /*
                this.pumps[i].update({
                    current: this.heaters[hnum].pumpcurrent,
                    flow: this.heaters[hnum].pumpflow,
                    modelrecommended: this.heaters[hnum].pumprecommended,
                    speedrecommended: this.heaters[hnum].pumpspeedrecommended,
                });
                this.heatercount++;
                */
            }
            this.current += this.pumps[i].current;
        }

        if (this.current > this._currentErr) {
            this.error.current = `Current greater than ${this._currentErr} A`;
        } else if (this.current > this._currentWarn) {
            this.warning.current = `Current greater than ${this._currentWarn} A`;
        }
        this.powerneeded = this.area * this.powerperarea;
        this.tempdrop = (this.powerneeded / Units.convertFlow(this.flow, 'lb/h')) || 0;
        this.returnwatertemp = (this.floor === "CONCRETE") ? 115 : 130;
        this.tanktemp = this.returnwatertemp + this.tempdrop;

        for (const p in this.pumps) {
            this.pumps[p].freeze();
        }
        for (const p in this.heaters) {
            this.heaters[p].update({
                powerneeded: this.powerneeded,
                tanktemp: this.tanktemp,
            });
            this.heaters[p].freeze();
            this.powerinput += this.heaters[p].output;
        }
        this.converted = {
            returnwatertemp: Units.convertTemp(this.returnwatertemp, this.units.returnwatertemp),
            tanktemp: Units.convertTemp(this.tanktemp, this.units.tanktemp),
            powerneeded: Units.convertPower(this.powerneeded, this.units.powerneeded),
            powerinput: Units.convertPower(this.powerinput, this.units.powerinput),
            tempdrop: Units.convertTemp(this.tempdrop, this.units.tempdrop, true),
            flow: Units.convertFlow(this.flow, this.units.flow),
            area: Units.convertArea(this.area, this.units.area),
            powerperarea: Units.convertPowerPerArea(this.powerperarea, this.units.powerperarea),
            current: Units.convertCurrent(this.current, this.units.current),
            looplength: Units.convertLength(this.looplength, this.units.looplength),
            fluid: Units.convertVolume(this.fluid, this.units.fluid),
        }
        Object.freeze(this);
    }
    public update(sys: Partial<ISystem>): System {
        console.log(sys);
        const system = this.toJSON();

        if (sys.pumps) {
            for (let i = 1; i <= this.maxpumps; i++) {
                system.pumps[i] = { ...system.pumps[i], ...sys.pumps[i] };
            }
        }
        if (sys.heaters) {
            for (let i = 0; i < this.maxheaters; i++) {
                system.heaters[i] = { ...system.heaters[i], ...sys.heaters[i] };
            }
        }
        system.floor = sys.floor || system.floor;
        system.powerperarea = sys.powerperarea || system.powerperarea;
        system.units = sys.units || system.units;
        system.label = sys.label || system.label;
        system.accessories = { ...system.accessories, ...sys.accessories };
        return new System(system);
    }

    public toJSON(): ISystem {
        const pumps: IPump[] = [];
        for (const p in this.pumps) {
            pumps[p] = this.pumps[p].toJSON();
        }
        const heaters: IHeater[] = [];
        for (const p in this.heaters) {
            heaters[p] = this.heaters[p].toJSON();
        }
        return {
            pumps,
            heaters,
            floor: this.floor,
            powerperarea: this.powerperarea,
            units: this.units,
            label: this.label,
            accessories: this.accessories,
        };
    }

    private _unitChoice(type: UnitType): ISystemUnits {
        if (type === "Metric") {
            return this._metric();
        }
        return this._imperial();
    }

    private _metric(): ISystemUnits {
        return {
            type: "Metric",
            returnwatertemp: '°C',
            tanktemp: '°C',
            powerneeded: 'kW',
            powerinput: 'kW',
            tempdrop: '°C',
            flow: "lt/m",
            area: 'm²',
            powerperarea: 'kW/m²',
            current: 'A',
            looplength: 'm',
            fluid: 'lt',
        };
    }

    public new(sys?: Partial<ISystem>): System {
        return new System({...sys, units: this.units});
    }


    public _imperial(): ISystemUnits {
        return {
            type: "Imperial",
            returnwatertemp: '°F',
            tanktemp: '°F',
            powerneeded: 'Btu/h',
            powerinput: 'Btu/h',
            tempdrop: '°F',
            flow: "gal/m",
            area: 'ft²',
            powerperarea: 'Btu/h/ft²',
            current: 'A',
            looplength: 'ft',
            fluid: 'gal',
        };
    }
}